import React from "react";

const HomeAccBinding = React.lazy(() =>
  import("../display/containers/snap/account-binding/Home")
);
const LoginAccBinding = React.lazy(() =>
  import("../display/containers/snap/account-binding/Binding")
);
const FotoWajahAccBinding = React.lazy(() =>
  import("../display/containers/snap/account-binding/FotoWajah")
);
const BindedAccBinding = React.lazy(() =>
  import("../display/containers/snap/account-binding/Binded")
);
const LivenessAccBinding = React.lazy(() =>
  import("../display/containers/snap/account-binding/Liveness")
);
const OtpAccBinding = React.lazy(() =>
  import("../display/containers/snap/account-binding/Otp")
);
const PaymentDebit = React.lazy(() =>
  import("../display/containers/snap/direct-debit/Payment")
);
const PinDebit = React.lazy(() =>
  import("../display/containers/snap/direct-debit/Pin")
);
const SuccessDebit = React.lazy(() =>
  import("../display/containers/snap/direct-debit/Success")
);

const LoadAuthPayment = React.lazy(() =>
  import("../display/containers/snap/auth-payment/Payment")
);
const PinAuthPayment = React.lazy(() =>
  import("../display/containers/snap/auth-payment/Pin")
);
const SuccessAuthPayment = React.lazy(() =>
  import("../display/containers/snap/auth-payment/Success")
);
const OnProcessAuthPayment = React.lazy(() =>
  import("../display/containers/snap/auth-payment/OnProcess")
);

const ibtConfirmation = React.lazy(() =>
  import("../display/containers/snap/interbank-transfer/Confirmation")
);
const ibtPin = React.lazy(() =>
  import("../display/containers/snap/interbank-transfer/Pin")
);
const ibtSuccess = React.lazy(() =>
  import("../display/containers/snap/interbank-transfer/Success")
);
const itbLoading = React.lazy(() =>
  import("../display/containers/snap/interbank-transfer/Loading")
);
const ibtOnProcess = React.lazy(() =>
  import("../display/containers/snap/interbank-transfer/OnProcess")
);

const routes = [
  // { path: "/", exact: true, name: "Landing", component: Landing },
  { path: "/home", exact: true, name: "HomeAccBinding", component: HomeAccBinding },
  { path: "/binding", exact: true, name: "LoginAccBinding", component: LoginAccBinding },
  { path: "/foto", exact: true, name: "FotoWajahAccBinding", component: FotoWajahAccBinding },
  { path: "/binded", exact: true, name: "BindedAccBinding", component: BindedAccBinding },
  { path: "/binding-complete", exact: true, name: "BindedAccBinding", component: BindedAccBinding },
  { path: "/liveness", exact: true, name: "LivenessAccBinding", component: LivenessAccBinding },
  { path: "/otp", exact: true, name: "OtpAccBinding", component: OtpAccBinding },
  { path: "/debit-payment", exact: true, name: "PaymentDebit", component: PaymentDebit },
  { path: "/debit-pin", exact: true, name: "PinDebit", component: PinDebit },
  { path: "/debit-success/:id", exact: true, name: "SuccessDebit", component: SuccessDebit },

  { path: "/auth-payment", exact: true, name: "PaymentDebit", component: LoadAuthPayment },
  { path: "/auth-payment-pin", exact: true, name: "PinDebit", component: PinAuthPayment },
  { path: "/auth-payment-success/:id", exact: true, name: "SuccessDebit", component: SuccessAuthPayment },
  { path: "/auth-payment/process", exact: true, name: "itbLoading", component: OnProcessAuthPayment },

  { path: "/interbank-transfer", exact: true, name: "ibtConfirmation", component: ibtConfirmation },
  { path: "/interbank-transfer/pin", exact: true, name: "ibtPin", component: ibtPin },
  { path: "/interbank-transfer/confirm-success", exact: true, name: "ibtPin", component: ibtSuccess },
  { path: "/interbank-transfer/successful-debit/:id", exact: true, name: "ibtPin", component: ibtSuccess },
  { path: "/interbank-transfer/process", exact: true, name: "itbLoading", component: itbLoading },
  { path: "/interbank-transfer/onprocess", exact: true, name: "itbLoading", component: ibtOnProcess },
  { path: "/interbank-transfer/direct-partner/onprocess/:id", exact: true, name: "itbLoading", component: ibtOnProcess },
];

export default routes;
